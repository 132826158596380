import Header from "../../components/Header/Header";
import {useState} from "react";

export default function Faq() {
    const [openVisitorsIndex, setOpenVisitorsIndex] = useState<number | null>(null);
    const [openSellersIndex, setOpenSellersIndex] = useState<number | null>(null);
    const visitorsList = [
        {
            question: 'What does this tool do?',
            answer: '<div>Our tool helps you find alternative car parts at better prices. Simply paste the link of the product you\'re interested in, press "Search," and get a list of similar or alternative options to compare.</div>'
        },
        {
            question: 'How do I use the search engine?',
            answer: '<div>It’s simple! <br/>1. Paste the url link of a car part you want to find alternatives for. It can be from any website.<br/>2. Press the "Search" button.<br/>3. Browse the list of similar or alternative options and choose the one that suits your needs.<br/>4. Click on the product to proceed with the purchase.<div>',
        },
        {
            question: 'Is this service free to use?',
            answer: '<div>Yes, our search engine is completely free to use. You only pay for the parts you decide to purchase from the supplier.<div>',
        },
        {
            question: 'Do I need to create an account to use this tool?',
            answer: '<div>No account is required! Just paste the url link of the car part you look for and start the search.<div>',
        },
        {
            question: 'Do you sell the car parts?',
            answer: '<div>No, we don’t sell car parts directly. Our tool connects you with suppliers where you can complete your purchase.<div>',
        },
        {
            question: 'Where do the prices come from?',
            answer: '<div>We gather prices from various suppliers and marketplaces to provide you with the best deals available.<div>',
        },
        {
            question: 'Is there customer support available if I have issues? ',
            answer: '<div>Yes, you can reach out to us at Discord - Client support channel,  and we’ll be happy to assist you.<div>',
        },
    ];
    const sellersList = [
        {
            question: 'How can I list my products on Alt.parts?',
            answer: '<div>If you\'re a car parts seller and want your inventory to be found by car enthusiasts searching for alternatives, contact us. We integrate with major marketplaces and suppliers to showcase your products directly to buyers.</div>'
        },
        {
            question: 'What are the benefits of being listed on Alt.parts?',
            answer: '<div>By joining Alt.parts, your products become more discoverable to a highly engaged audience actively searching for car parts.<br/>You gain:<br/>' +
                'More visibility in front of customers looking for alternative or aftermarket options.<br/>' +
                'Higher conversions, as users are already intent on purchasing.<br/>' +
                'Seamless integration with our AI-powered search engine.<div>',
        },
        {
            question: 'Does it cost anything to list my products?',
            answer: '<div>We operate on an affiliate model, meaning you only pay when users purchase through our platform. There’s no upfront cost to get listed.<div>',
        },
        {
            question: 'What types of car parts can I list?',
            answer: '<div>We support a wide range of car parts, from OEM replacements to aftermarket and performance upgrades. If your parts match what users search for, they can be listed.<div>',
        },
        {
            question: 'How do I get started?',
            answer: '<div>Get in touch with us to discuss integration options. Whether you sell through a marketplace or your own website, we’ll help you connect with car enthusiasts looking for what you offer.<div>',
        },
    ];


    return (
        <div className="Faq pb-20">
            <Header/>
            <div className="max-w-screen-lg mx-auto w-full mt-16 sm:mt-28 px-5">
                <div className="p-16 bg-black rounded-3xl text-text max-md:px-0 max-md:py-8 max-md:bg-background">
                    <div className="text-primary uppercase text-xs leading-3 font-medium tracking-widest">
                        FREQUENTLY ASKED QUESTIONS
                    </div>
                    <div className="text-2xl font-bold mt-5">
                        Here are some commonly asked questions by the community
                    </div>
                    <div className="space-y-2 mt-8">
                        <div className="text pt-3 pb-3">For Visitors</div>
                        {
                            visitorsList.map(
                                (item, index) =>
                                    <div
                                        className={`faq-item bg-background rounded-xl ${index === openVisitorsIndex ? 'open' : ''}`}>
                                        <button
                                            className={"leading-4 text-text text-left flex items-center justify-between font-bold md:px-5 py-5 w-full"}
                                            onClick={() => {
                                                index !== openVisitorsIndex ? setOpenVisitorsIndex(index) : setOpenVisitorsIndex(null)
                                            }}
                                        >
                                            {item.question}
                                            <i className={`fas ${index !== openVisitorsIndex ? 'fa-chevron-down' : 'fa-chevron-up'}`}></i>
                                        </button>
                                        <div className="answer"
                                             dangerouslySetInnerHTML={{__html: item.answer}}>
                                        </div>
                                    </div>
                            )
                        }

                        <div className="text pt-10 pb-3">For Car Parts Sellers</div>
                        {
                            sellersList.map(
                                (item, index) =>
                                    <div
                                        className={`faq-item bg-background rounded-xl ${index === openSellersIndex ? 'open' : ''}`}>
                                        <button
                                            className={"leading-4 text-text text-left flex items-center justify-between font-bold md:px-5 py-5 w-full"}
                                            onClick={() => {
                                                index !== openSellersIndex ? setOpenSellersIndex(index) : setOpenSellersIndex(null)
                                            }}
                                        >
                                            {item.question}
                                            <i className={`fas ${index !== openSellersIndex ? 'fa-chevron-down' : 'fa-chevron-up'}`}></i>
                                        </button>
                                        <div className="answer"
                                             dangerouslySetInnerHTML={{__html: item.answer}}>
                                        </div>
                                    </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

