export default function HomeBackground({ show }: { show: boolean }) {
  return (
    <div
      className={
      `HomeBackground fixed mt-14 top-0 left-0 w-lvw 
        h-[calc(100vh-64px)] -z-10 bg-cover bg-bottom bg-no-repeat
        bg-[url('../assets/img/supra-box-sm-2x.jpg')]
        sm:bg-[url('../assets/img/supra-box-lg-2x.jpg')] 
        ${show ? 'opacity-50 sm:opacity-100' : 'opacity-0'} transition-opacity`}>
    </div>
  );
}
