import React, {useEffect} from 'react';
import './App.scss';
import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import Home from './pages/Home/Home';
import About from './pages/About/About';
import {ResponsivenessProvider} from './context/ResponsivenessContext';
import TermsAndConditions from "./pages/TermsAndConditions/TermsAndConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import Community from "./pages/Community/Community";
import Faq from "./pages/Faq/Faq";

const router = createBrowserRouter(
    [
        {path: "/*", element: <Home/>,},
        {path: "/search", element: <Home/>,},
        {path: "/about", element: <About/>,},
        {path: "/community", element: <Community/>,},
        {path: "/terms-and-conditions", element: <TermsAndConditions/>,},
        {path: "/privacy-policy", element: <PrivacyPolicy/>,},
        {path: "/faq", element: <Faq/>,},
    ]
);


function App() {
    return (
        <ResponsivenessProvider>
            <RouterProvider router={router}/>
        </ResponsivenessProvider>
    );
}

export default App;
