import Header from "../../components/Header/Header";
export default function Faq() {
    return (
        <div className="PrivacyPolicy pb-20">
            <Header/>
            <div className="max-w-screen-lg mx-auto w-full mt-16 sm:mt-28 px-5">
                <div className="p-16 bg-black rounded-3xl text-text max-md:px-0 max-md:py-8 max-md:bg-background">
                    <div className="text-2xl font-bold leading-6">Privacy Policy</div>
                    <div className="text-base font-bold leading-4 mt-5 max-md:text-sm">Last updated: February 26, 2025</div>
                    <div className="text-base mt-7 prose leading-7 max-md:text-sm max-md:leading-[22px]">
                        <p>
                            Welcome to Alt Parts! We are committed to respecting and protecting your privacy. This
                            Privacy Policy explains how we collect, use, and disclose information when you visit or use
                            our website and services (collectively, the “Services”). By using Alt Parts, you agree to
                            the terms of this Privacy Policy.
                        </p>
                        <br/>
                        <section>
                            <h2>1. Information We Collect</h2>
                            <p>
                                We collect information to provide, improve, and personalize our Services. The types of
                                information we collect include:
                            </p>
                            <ul>
                                <li>
                                    <strong>Personal Information:</strong> When you sign up for an account, contact us,
                                    or use certain features, we may collect personal details such as your name, email
                                    address, and phone number.
                                </li>
                                <li>
                                    <strong>Usage Data:</strong> We automatically collect information about your
                                    interactions with the Services, including IP address, device type, browser type,
                                    referring/exit pages, and the date and time of your visits.
                                </li>
                                <li>
                                    <strong>Cookies and Similar Technologies:</strong> We use cookies, pixels, and
                                    similar tracking technologies to enhance your experience, gather analytics, and
                                    improve the functionality of the Services. You can control cookie settings through
                                    your browser.
                                </li>
                            </ul>
                        </section>
                        <br/>
                        <section>
                            <h2>2. How We Use Your Information</h2>
                            <p>We may use the information collected for the following purposes:</p>
                            <ul>
                                <li>
                                    <strong>Providing and Improving Services:</strong> To process your searches, deliver
                                    results, and personalize your experience.
                                </li>
                                <li>
                                    <strong>Customer Support:</strong> To respond to your inquiries, troubleshoot
                                    issues, and provide customer care.
                                </li>
                                <li>
                                    <strong>Communication:</strong> To send updates, promotional materials, and other
                                    communications related to Alt Parts. You may opt-out of promotional communications
                                    at any time.
                                </li>
                                <li>
                                    <strong>Analytics:</strong> To understand user behavior, diagnose technical issues,
                                    and improve our Services.
                                </li>
                                <li>
                                    <strong>Legal Compliance:</strong> To comply with applicable laws, regulations, and
                                    legal requests.
                                </li>
                            </ul>
                        </section>
                        <br/>
                        <section>
                            <h2>3. How We Share Your Information</h2>
                            <ul>
                                <li>
                                    <strong>With Service Providers:</strong> We work with third-party service providers
                                    to facilitate our Services. These providers are required to protect your information
                                    and use it only for the purposes we specify.
                                </li>
                                <li>
                                    <strong>In Connection with Business Transactions:</strong> In the event of a merger,
                                    acquisition, or sale of assets, your information may be transferred as part of the
                                    transaction.
                                </li>
                                <li>
                                    <strong>For Legal and Security Reasons:</strong> We may disclose information to
                                    protect the rights, property, and safety of Alt Parts, our users, or others, or to
                                    comply with legal obligations.
                                </li>
                            </ul>
                        </section>
                        <br/>
                        <section>
                            <h2>4. Your Choices and Rights</h2>
                            <ul>
                                <li>
                                    <strong>Access and Correction:</strong> You may request access to or correction of
                                    your personal information.
                                </li>
                                <li>
                                    <strong>Deletion:</strong> You may request deletion of your personal information,
                                    though certain information may be retained for legal or operational purposes.
                                </li>
                                <li>
                                    <strong>Opt-Out:</strong> You can opt out of receiving promotional communications
                                    from us by following the instructions in those communications.
                                </li>
                                <li>
                                    <strong>Cookies:</strong> You can adjust your cookie settings through your browser
                                    to control what information is collected.
                                </li>
                            </ul>
                        </section>
                        <br/>
                        <section>
                            <h2>5. Security</h2>
                            <p>
                                We take reasonable measures to protect your information from unauthorized access, use,
                                or disclosure. However, no internet or email transmission is ever fully secure, so we
                                encourage you to take special care in deciding what information you share with us.
                            </p>
                        </section>
                        <br/>
                        <section>
                            <h2>6. Children’s Privacy</h2>
                            <p>
                                Alt Parts does not knowingly collect personal information from children under 13. If we
                                learn that we have inadvertently collected personal information from a child, we will
                                take steps to delete the information as soon as possible.
                            </p>
                        </section>
                        <br/>
                        <section>
                            <h2>7. Changes to This Privacy Policy</h2>
                            <p>
                                We may update this Privacy Policy from time to time. When we do, we will post the
                                updated version on our website and update the “Last Updated” date at the top. We
                                encourage you to review this page periodically for any changes.
                            </p>
                        </section>
                        <br/>
                        <section>
                            <h2>8. Contact Us</h2>
                            <p>
                                If you have any questions or concerns about this Privacy Policy or our privacy
                                practices, please contact us at:
                            </p>
                        </section>
                        <br/>
                        <br/>

                        LLC Alt parts
                        <br/>
                        Email: <a href="mailto:legal@alt.parts">legal@alt.parts</a>

                    </div>
                </div>
            </div>
        </div>
    )
}

