import React, {useState} from 'react';
import axios from "axios";
import TagManager from "react-gtm-module";
import {useSearchParams} from "react-router-dom";

interface Props {
    item: any;
    searchedProduct?: any;
    index: number;
}

export default function Result({item, searchedProduct, index}: Props) {
    const [searchParams, setSearchParams] = useSearchParams();
    const trimmedLink = item?.link ? item?.link.split('?')[0] : item?.product_link;

    async function navigateToProduct(): Promise<void> {

        TagManager.dataLayer({
            dataLayer: {
                event: 'product_click',
                clickedProduct: item?.link,
                clickedProductListPosition: index + 1,
                clickedProductName: item?.title,
                clickedProductPrice: parseFloat(item?.price?.extracted_value).toFixed(2),
                clickedProductCurrency: item?.price?.currency,
                clickedProductNormalizedPrice: parseFloat(item?.normalizedPrice?.value).toFixed(2),
                clickedProductNormalizedCurrency: item?.normalizedPrice?.currency,
                clickedProductPriceDiff: searchedProduct?.price - item?.normalizedPrice?.value,
                clickedProductPriceDiffPercentage: (searchedProduct?.price - item?.normalizedPrice?.value)/searchedProduct?.price*100,
                searchedProductName: searchedProduct?.name,
                searchedProductPrice: searchedProduct?.price && typeof searchedProduct?.price === 'string' ? parseFloat(searchedProduct?.price).toFixed(2) : searchedProduct?.price,
                searchedProductCurrency: searchedProduct?.currencyRaw ||  searchedProduct?.currency,
                searchedProductSku: searchedProduct?.sku,
                searchString: searchParams.get('search_string')
            }
        })

        if (!item?.link) {
            const directLinkResponse = (await axios.get(`https://23lqld5h45.execute-api.eu-west-3.amazonaws.com/dev/getDirectLink?url=${encodeURIComponent(item?.product_link)}`));
            if (!directLinkResponse?.data?.directLink?.length) {
                throw new Error('Failed to get directLink');
            }
            const directLink: string = directLinkResponse.data.directLink[0];
            window.open(directLink, '_blank');
        } else {
            window.open(item?.link, '_blank');
        }
    }

    function addAffiliateParamsToLink(link: string): any {
        //     For amazon:
        const amazonParam = {
            tag: 'altparts-20'
        }
    }

    return (
        <button onClick={navigateToProduct}
                className={`Result text-text text-left flex w-full xxs:flex-row max-xxs:flex-col mt-5 sm:mt-10 p-3 sm:p-5 rounded-2xl border-card-background border hover:bg-card-background-hover hover:border-primary active:bg-card-background-light ${item?.weightAttributes?.totalScore > 70 ? 'border-primary-20 bg-card-background' : 'bg-black'}`}>
            <div className={"sm:w-40 sm:h-40 xs:w-36 xxs:w-28 w-full h-40"}>
                <img src={item?.thumbnail} alt={"Main product img missing"}
                     className="rounded-xl object-cover sm:h-40 xs:w-36 xxs:w-28 w-full h-40"/>
            </div>
            <div className="details xxs:ml-3 sm:ml-5 flex-1 flex flex-col justify-between items-start">
                <div>
                    <div className={"text-sm sm:text-base font-bold mt-2 sm:mt-3 text-ellipsis line-clamp-2"}>
                        {item?.title}
                    </div>
                    <div className="flex flex-row items-center text-xs sm:text-sm mt-2">
                        <i className="fal fa-link text-primary"></i>
                        <div className="ml-2 capitalize">
                            {item?.source?.toLowerCase()}
                        </div>
                    </div>
                </div>
                <div className="flex flex-row items-center justify-center mt-3">
                    <div
                        className={`price-button flex ${item?.normalizedPrice?.value < searchedProduct?.price ? 'cheaper' : 'same-price'}`}>
                        {
                            item?.normalizedPrice?.value < searchedProduct?.price ?
                                <div
                                    className="left text-xs font-medium text-black bg-text rounded-l-lg h-9 sm:px-4 px-3 flex items-center justify-center"
                                >{Math.round(100 - item?.normalizedPrice?.value / searchedProduct?.price * 100)}% CHEAPER
                                </div>
                                : null
                        }
                        <div
                            className="right sm:text-xl text-lg h-9 rounded-r-lg border-2 border-text text-text font-bold px-3 sm:px-4 flex items-center justify-center">{item?.price?.currency}{Math.round(item?.price?.extracted_value)}</div>
                    </div>
                    {
                        item?.in_stock !== undefined ?
                            <div className={"text-xs ml-3"}>
                                {
                                    item?.in_stock ?
                                        <span>
                                            <i className="far fa-check text-primary"></i>
                                            {item?.in_stock} IN STOCK
                                        </span>
                                        :
                                        <span>
                                            <i className="far fa-times text-s-tier"></i>
                                            {item?.in_stock} OUT OF STOCK
                                        </span>
                                }
                            </div>
                            : null
                    }
                </div>
            </div>
        </button>
    )
}
