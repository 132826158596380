import React from 'react';
import {ServerMessage} from '../../models/server-message.model';

interface Props {
    serverMessage: ServerMessage | null;
}

export default function LoadingSearch({serverMessage}: Props) {
    if (!serverMessage) {
        return null;
    }
    if (!serverMessage.text) {
        return null;
    }
    return (
        <div
            className={(serverMessage.action === 'error' ? 'error ' : '') + 'LoadingSearch w-full h-40 mt-5 sm:mt-10 bg-card-background rounded-2xl text-text text-center flex flex-col items-center justify-center font-bold text-xl relative px-4'}>
            <div>
                {
                    serverMessage.text.split('').map((c, i) => <span className={"char z-10 relative"}
                                                                     key={Math.random() * 10 + c + i}>{c}</span>)
                }
            </div>
            {
                serverMessage.action !== 'error' ?
                    <div className={"text-text-20 text-sm text-center absolute z-10 bottom-4"}>More complex searches
                        take around
                        10
                        seconds.
                    </div>
                    : null
            }
        </div>
    )
}
