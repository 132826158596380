import React from "react";

export function YouTubeVideo({close}: Props) {
    return (
        <div
            className="fixed z-50 left-0 top-0 w-full h-full flex flex-col items-center justify-center bg-black/70 backdrop-blur-md">
            <div className="bg absolute left-0 top-0 w-full h-full" onClick={close}></div>
            <div className={"w-[45vh] h-full relative flex "}>
                <i className="far fa-times text-text text-2xl relative ml-auto mt-10" onClick={close}></i>
                <div className={"w-full pt-[178%] absolute  top-1/2 left-0 -translate-y-1/2"}>
                    <iframe
                        className={"rounded-3xl h-full w-full absolute top-1/2 left-0 -translate-y-1/2"}
                        src={`https://www.youtube.com/embed/fCew8V9sgzo?modestbranding=1&autohide=1&showinfo=0&controls=0`}
                        title="YouTube Video Player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                </div>
            </div>
        </div>
    );
}


interface Props {
    close: () => void;
}
