import React, {useEffect, useState} from 'react';
import SearchBar from '../../components/SearchBar/SearchBar';
import {useSearchParams} from 'react-router-dom';
import Header from '../../components/Header/Header';
import LoadingSearch from '../../components/LoadingSearch/LoadingSearch';
import HomeBackground from '../../components/HomeBackground/HomeBackground';
import Result from '../../components/Result/Result';
import useWebsocket from '../../hooks/use-websocket';
import {useOnLocationChange} from '../../hooks/use-on-location-change';
import {YouTubeVideo} from "../../components/YoutubeVideo/YoutubeVideo";
import TagManager from "react-gtm-module";


function Home() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [searchedProduct, setSearchedProduct] = useState<any>(null);
    const [results, setResults] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [serverError, setServerError] = useState(false);
    const [showYoutubeVideo, setShowYoutubeVideo] = useState(false);
    const [serverMessage, sendMessage, socket] = useWebsocket();

    useOnLocationChange(search, socket);

    useEffect(onServerMessage, [serverMessage]);

    function onServerMessage(): void {
        if (serverMessage?.action === 'error') {
            setServerError(true);
            setLoading(false);
            return;
        }
        setServerError(false);

        if (serverMessage?.action === 'search-complete') {
            setLoading(false);
            const searchedProduct = serverMessage.data?.scrapedProduct
            if (searchedProduct) {
                setSearchedProduct(searchedProduct);

                TagManager.dataLayer({
                    dataLayer: {
                        event: 'found_product',
                        searchedProductName: searchedProduct?.name,
                        searchedProductPrice: searchedProduct?.price && typeof searchedProduct?.price === 'string' ? parseFloat(searchedProduct?.price).toFixed(2) : searchedProduct?.price,
                        searchedProductCurrency: searchedProduct?.currencyRaw ||  searchedProduct?.currency,
                        searchedProductSku: searchedProduct?.sku,
                        searchString: searchParams.get('search_string'),
                    }
                })
            }
            setResults(serverMessage.data.results);
            return;
        }

    }

    function changeSearchParams(searchString: string, perfectFit = false): void {
        setError('');
        setSearchParams({search_string: searchString, perfect_fit: perfectFit.toString()});
    }

    async function search(searchString: string, perfectFit = false): Promise<void> {
        setLoading(true);
        // TagManager.dataLayer({
        //     dataLayer: {
        //         event: 'searchTrigger',
        //         searchString: searchString
        //     }
        // })
        sendMessage({
            command: 'start-search',
            params: {search_string: searchString, perfect_fit: perfectFit}
        });
    }

    function stopSearch() {
        // TODO: Actually cancel the search request
        setLoading(false);
    }


    function filterAndSort(items: any[]): any[] {
        items = sortByVendor(items);
        items = filterByPrice(items);
        return items;
    }

    // Don't show the same vendor as the searched-for product in the first 5 results
    function sortByVendor(items: any[]): any[] {

        // Separate ebay and non-ebay vendors
        let sameVendor = items.filter(item => item.source?.toLowerCase() === searchedProduct?.brand?.name?.toLowerCase());
        let otherVendors = items.filter(item => item.source?.toLowerCase() !== searchedProduct?.brand?.name?.toLowerCase());
        const restList = sameVendor.concat(otherVendors.slice(5)).sort((a, b) => b.weightAttributes?.totalScore - a.weightAttributes?.totalScore);
        return [...otherVendors.slice(0, 5), ...restList];
    }


    function filterByPrice(items: any[]): any[] {
        return items.filter(item => parseFloat(item.normalizedPrice?.value) < parseFloat(searchedProduct?.price));
    }

    // If there was a comparison to be made, split items between tiers.
    let topItems: any[];
    let restItems: any[] = [];
    const filteredResults = filterAndSort(results);
    if (searchedProduct) {
        topItems = filteredResults.filter((item: any) => item?.weightAttributes?.titleSimilarityScore > 7).slice(0, 60);
        restItems = filteredResults.filter((item: any) => item?.weightAttributes?.titleSimilarityScore <= 7);
    } else {
        // If there was no searched product to compare, put all items in the top list
        topItems = results;
    }

    const productUrl: string = searchParams.get('search_string') || '';
    const isPerfectFitOn: boolean = searchParams.get('perfect_fit') === 'true';


    return (
        <div className="Home pb-20">
            <HomeBackground show={!results?.length && !error && !loading && !serverError}/>
            {showYoutubeVideo ? <YouTubeVideo close={() => setShowYoutubeVideo(false)}/> : null}
            <div
                className="flex flex-col row-start-2 justify-center items-center max-w-screen-lg mx-auto w-full mt-16 sm:mt-28 px-5">
                <Header/>
                {
                    !loading && !serverError && !results?.length && !error ?
                        <div className={"flex flex-col items-center"}>
                            <div
                                className={`hero-text font-bold max-sm:text-2xl sm:text-4xl mt-12 sm:mt-16 sm:text-center text-center ${!results?.length && !error && !loading ? '' : 'hide'}`}>
                                Car Part Search - <span className={"text-primary"}>Made Simple</span>
                            </div>
                            <button
                                className={`flex items-center bg-background text-base font-bold rounded-xl py-2 px-3 sm:p-3 sm:pr-4 text-text mt-5 sm:mt-12 mb-5`}
                                onClick={() => setShowYoutubeVideo(true)}
                            >
                                <i className="fas fa-play-circle text-2xl sm:text-3xl text-text mr-3"></i> How does it
                                work?
                            </button>
                        </div>

                        : null
                }

                <SearchBar
                    value={productUrl}
                    loading={loading}
                    error={error}
                    onSearch={changeSearchParams}
                    onForceStopSearch={stopSearch}
                />


                {error ? <div className="text-white ml-2">{error}</div> : null}

                {loading || serverError ? <LoadingSearch serverMessage={serverMessage}/> : null}

                {searchedProduct && !loading && !serverError
                    ?
                    <div
                        id="searched-product"
                        className="w-full mt-5 sm:mt-10 p-5 bg-card-background flex flex-col sm:flex-row rounded-2xl"
                    >
                        <div className="product-images w-full sm:w-[280px] md:w-[330px] lg:w-[400px]">
                            <div className={"main-image"}>
                                <img src={searchedProduct?.mainImage?.url}
                                     alt={"Main product img missing"}
                                     className="rounded-lg object-cover  aspect-square"
                                />
                            </div>
                            {
                                searchedProduct?.images?.slice(0, 4).map((image: any) => (
                                    <div className={"secondary-image"}
                                         key={image?.url}>
                                        <img
                                            src={image?.url}
                                            alt={"No img"}
                                            className="rounded-lg mt-3 first:mt-0 object-cover aspect-square"
                                        />
                                    </div>
                                ))
                            }
                        </div>

                        <div className="details mt-3 sm:ml-10 sm:mt-0 flex-1 flex flex-col justify-between">
                            <div>
                                <div className="flex flex-row items-center text-sm">
                                    <i className="fal fa-link text-primary"></i>
                                    <div className="ml-2 capitalize">
                                        {searchedProduct?.brand?.name?.toLowerCase()}
                                    </div>
                                </div>
                                <div
                                    className={"text-lg sm:text-xl font-bold mt-2 sm:mt-3 text-ellipsis line-clamp-1 sm:line-clamp-2"}>
                                    {searchedProduct?.name}
                                </div>
                            </div>
                            <div>
                                <div
                                    className={"text-text-70 text-sm text-ellipsis sm:line-clamp-4 line-clamp-2 mt-2 sm:mt-3"}>
                                    {searchedProduct?.description}
                                </div>
                                <div className="price-button flex mt-4 sm:mt-3">
                                    <div
                                        className="left text-xs font-medium text-black bg-text rounded-l-lg h-9 sm:px-4 px-3 flex items-center justify-center">ORIGINAL
                                        PRICE
                                    </div>
                                    <div
                                        className="right sm:text-xl text-lg h-9 rounded-r-lg border-2 border-text text-text font-bold px-3 sm:px-4 flex items-center justify-center">{searchedProduct?.currencyRaw}{Math.round(searchedProduct?.price)}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    null
                }
                <div className="flex flex-col w-full">
                    {
                        topItems?.length && !loading
                            ? <div>
                                <hr className={"mt-5 sm:mt-10 bg-text-10 border-text-10"}/>
                                <div className={"flex mt-5 sm:mt-10 flex-row items-center justify-between"}>
                                    <div className={"text-base sm:text-xl font-bold flex-1 pr-5 leading-5"}>
                                        We found {topItems.length} {topItems.length > 1 ? 'alternatives' : 'alternative'}!
                                    </div>
                                    {/*<div className={"flex flex-row items-center"}>*/}
                                    {/*    {*/}
                                    {/*        searchedProduct ?*/}
                                    {/*            <label className={"bg-card-background rounded-md py-2 px-3 font-bold mr-5"}*/}
                                    {/*                   onClick={() => {*/}
                                    {/*                       window.setTimeout(() => changeSearchParams(productUrl, !isPerfectFitOn), 100)*/}
                                    {/*                   }}>*/}
                                    {/*                <input type="checkbox" checked={isPerfectFitOn} className={"mr-2"}*/}
                                    {/*                       onChange={() => null}/>*/}
                                    {/*                Perfect Fit*/}
                                    {/*            </label>*/}
                                    {/*            : null*/}
                                    {/*    }*/}
                                    {/*    <button*/}
                                    {/*        className={"border border-primary-30 bg-primary-10 rounded-lg py-2 px-3 text-text text-sm font-medium flex items-center"}>*/}
                                    {/*        <i className="far fa-filter mr-2"></i>*/}
                                    {/*        Fine tune*/}
                                    {/*    </button>*/}
                                    {/*    <button*/}
                                    {/*        className={"border border-primary-30 bg-primary-10 rounded-lg py-2 px-3 text-text text-sm font-medium ml-3"}>*/}
                                    {/*        <i className="far fa-sort mr-2"></i>Sort*/}
                                    {/*    </button>*/}
                                    {/*</div>*/}
                                </div>
                                {
                                    topItems.length > 0 ?
                                        <div className={"mt-5"}>
                                            <span className={"hidden sm:block"}>Top choices based on your part:</span>
                                            {
                                                topItems.map((item: any, index: number) =>
                                                    <Result key={index} item={item} index={index}
                                                            searchedProduct={searchedProduct}/>
                                                )
                                            }
                                        </div>
                                        : null
                                }
                                {
                                    restItems.length > 0 ?
                                        <div className={"mt-5"}>
                                            {
                                                topItems.length > 0 ?
                                                    <div>
                                                        <hr className={"sm:my-10 my-5 bg-text-10 border-text-10"}/>
                                                        More results:
                                                    </div>
                                                    : null
                                            }
                                            {
                                                restItems.map((item: any, index: number) =>
                                                    <Result key={index} item={item} index={index}
                                                            searchedProduct={searchedProduct}/>
                                                )
                                            }
                                        </div>
                                        : null
                                }
                            </div>
                            :
                            searchedProduct && !topItems?.length && !loading ?
                                <div
                                    className={"flex flex-col items-center justify-center bg-text/10 text-white rounded-xl p-10 mt-5 max-md:p-5"}>
                                    <div className={"font-bold text-xl mb-5 text"}>
                                        Oops, we're still fine-tuning this category.
                                    </div>

                                    <div className={"max-w-[800px]"}>
                                        This car part category is currently under development to ensure the best
                                        results.
                                        It will be ready for you within the next few weeks. Stay tuned!
                                    </div>
                                </div>
                                : null
                    }
                </div>
            </div>
        </div>
    );
}

export default Home;
