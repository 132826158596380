import Header from "../../components/Header/Header";

export default function About() {
    return (
        <div className="About pb-20">
            <Header/>
            <div className="max-w-screen-lg mx-auto w-full mt-16 sm:mt-28 px-5">
                <div className="bg-black rounded-3xl text-text relative max-md:bg-background">
                    <div
                        className="absolute right-0 top-0 w-[38%] h-full bg-cover bg-center bg-no-repeat bg-[url('../assets/img/about-us-porsches.png')] rounded-tr-3xl rounded-br-3xl
                        max-md:relative max-md:w-full max-md:h-[200px] max-md:rounded-lg"
                    ></div>
                    <div className="p-16 mr-[38%] max-md:mr-0 max-md:px-0 max-md:py-12">
                        <div className="text-primary uppercase text-xs leading-3 font-medium tracking-widest">ABOUT ALT
                            PARTS
                        </div>
                        <div className="text-2xl font-bold mt-3">Passion project created for the community</div>
                        <div className="mt-16 leading-[26px] max-md:mt-12">
                            Alt Parts was created by three car enthusiasts who grew up wrenching and customizing cars,
                            always on the hunt for pieces to make them unique. We got tired of the endless Googling,
                            searching through forums, and piecing together parts lists just to find the right look. So,
                            we built Alt Parts—a place where car lovers can finally find the aesthetic pieces they need
                            to make their builds stand out.
                            <br/><br/>
                            For us, making a car look and feel unique is what matters most, and we believe styling
                            should be something everyone can access. Alt Parts is our way of sharing that passion,
                            offering parts that make customizing your car easier and more attainable. Whether it’s a
                            small detail or a full transformation, we’re here to help bring your vision to life.
                            <br/><br/><br/>
                            <div className="font-bold">Alt Parts Founders, Sandra, Marius and Rimantas.</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

