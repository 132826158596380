import Header from "../../components/Header/Header";

export default function TermsAndConditions() {
    return (
        <div className="TermsAndConditions pb-20">
            <Header/>
            <div className="max-w-screen-lg mx-auto w-full mt-16 sm:mt-28 px-5">
                <div className="p-16 bg-black rounded-3xl text-text max-md:px-0 max-md:py-8 max-md:bg-background">
                    <div className="text-2xl font-bold leading-6">Terms and Conditions</div>
                    <div className="text-base font-bold leading-4 mt-5 max-md:text-sm">Last updated: February 26, 2025
                    </div>
                    <div className="text-base mt-7 prose leading-7 max-md:text-sm max-md:leading-[22px]">
                        <p>
                            Thank you for choosing Alt Parts! By accessing or using our website and services
                            (collectively, the “Services”), you agree to abide by these Terms and Conditions. Please
                            read them carefully, as they govern your use of Alt Parts. If you do not agree to these
                            Terms, please discontinue use of our Services.
                        </p>
                        <br/>
                        <section>
                            <h2>1. Introduction</h2>
                            <p>
                                These Terms and Conditions ("Terms") govern your access to and use of Alt Parts,
                                including any content, functionality, and services offered. By using our Services, you
                                agree to be bound by these Terms, as well as our Privacy Policy. If you do not agree,
                                you must stop using Alt Parts.
                            </p>
                        </section>
                        <br/>
                        <section>
                            <h2>2. Our Services</h2>
                            <p>
                                Alt Parts is a platform designed to help users search for and compare car parts from
                                various third-party vendors. We do not sell or supply any parts directly, nor do we
                                endorse or assume responsibility for the quality, availability, or suitability of parts
                                offered by third-party providers.
                            </p>
                        </section>
                        <br/>
                        <section>
                            <h2>3. Changes to These Terms</h2>
                            <p>
                                Alt Parts reserves the right to modify or update these Terms at any time. We will notify
                                you of significant changes by posting the updated Terms on our website. Your continued
                                use of Alt Parts following the posting of revised Terms signifies your acceptance of
                                those changes. Please review these Terms regularly.
                            </p>
                        </section>
                        <br/>
                        <section>
                            <h2>4. User Accounts</h2>
                            <p>
                                To access some features of Alt Parts, you may be required to create an account. When
                                creating an account, you agree to:
                            </p>
                            <ul>
                                <li>Provide accurate, current, and complete information.</li>
                                <li>Keep your account details secure and confidential.</li>
                                <li>Notify us immediately of any unauthorized access or use of your account.</li>
                                <li>You are responsible for all activity that occurs under your account.</li>
                            </ul>
                        </section>
                        <br/>
                        <section>
                            <h2>5. User Conduct</h2>
                            <p>
                                By using Alt Parts, you agree not to engage in any behavior that:
                            </p>
                            <ul>
                                <li>Violates any applicable law or regulation.</li>
                                <li>Interferes with or disrupts the functionality of the Services.</li>
                                <li>Attempts to bypass security features, probe, or overload the platform.</li>
                                <li>Uses the Services for any fraudulent or harmful purpose.</li>
                            </ul>
                            <p>
                                Misuse of our Services may result in suspension or termination of your account.
                            </p>
                        </section>
                        <br/>
                        <section>
                            <h2>6. Intellectual Property Rights</h2>
                            <p>
                                All content, features, and functionality on Alt Parts, including text, graphics, logos,
                                icons, and images, are owned by Alt Parts or our licensors and are protected by
                                intellectual property laws. You agree not to reproduce, distribute, modify, or create
                                derivative works of any content from Alt Parts without our prior written permission.
                            </p>
                        </section>
                        <br/>
                        <section>
                            <h2>7. Third-Party Links and Content</h2>
                            <p>
                                Our Services may contain links to third-party websites, products, or services. These
                                links are provided for convenience, and Alt Parts does not control or endorse any
                                third-party sites or services. We are not responsible for the content, privacy policies,
                                or practices of third-party websites.
                            </p>
                        </section>
                        <br/>
                        <section>
                            <h2>8. Disclaimer of Warranties</h2>
                            <p>
                                ALT PARTS PROVIDES THE SERVICES “AS IS” AND “AS AVAILABLE,” WITHOUT WARRANTIES OF ANY
                                KIND, EITHER EXPRESS OR IMPLIED. Alt Parts does not guarantee that the Services will be
                                error-free, uninterrupted, or free of harmful components. We disclaim all warranties,
                                including implied warranties of merchantability, fitness for a particular purpose, and
                                non-infringement.
                            </p>
                        </section>
                        <br/>
                        <section>
                            <h2>9. Limitation of Liability</h2>
                            <p>
                                To the fullest extent permitted by law, Alt Parts and its affiliates, directors,
                                employees, and agents shall not be liable for any indirect, incidental, special,
                                consequential, or punitive damages, including lost profits or data, arising from or
                                related to your use of the Services. In no event shall Alt Parts' total liability exceed
                                the amount you paid us, if any, for access to the Services during the 12 months
                                preceding the event giving rise to liability.
                            </p>
                        </section>
                        <br/>
                        <section>
                            <h2>10. Indemnification</h2>
                            <p>
                                You agree to indemnify and hold harmless Alt Parts, its affiliates, directors,
                                employees, and agents from any claims, liabilities, damages, or expenses arising from
                                your use of the Services or your violation of these Terms.
                            </p>
                        </section>
                        <br/>
                        <section>
                            <h2>11. Termination</h2>
                            <p>
                                Alt Parts reserves the right to suspend or terminate your account and access to the
                                Services, with or without notice, if we believe you have violated these Terms or for any
                                other reason at our discretion. Upon termination, all rights granted to you under these
                                Terms will cease immediately.
                            </p>
                        </section>
                        <br/>
                        <section>
                            <h2>12. Governing Law and Dispute Resolution</h2>
                            <p>
                                These Terms are governed by the laws of [Insert Governing Jurisdiction], without regard
                                to its conflict of law principles. Any disputes arising from or relating to these Terms
                                or the Services will be resolved through binding arbitration in [Insert Jurisdiction],
                                unless otherwise agreed by the parties.
                            </p>
                        </section>
                        <br/>
                        <section>
                            <h2>13. Entire Agreement</h2>
                            <p>
                                These Terms, along with our Privacy Policy, constitute the entire agreement between you
                                and Alt Parts concerning your use of the Services. Any failure to enforce any right or
                                provision of these Terms shall not constitute a waiver of such right or provision.
                            </p>
                        </section>
                        <br/>
                        <section>
                            <h2>14. Contact Information</h2>
                            <p>
                                For questions, concerns, or additional information about these Terms, please contact us
                                at:
                            </p>
                        </section>
                        <br/>
                        <br/>

                        LLC Alt parts
                        <br/>
                        Email: <a href="mailto:legal@alt.parts">legal@alt.parts</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

