import Header from "../../components/Header/Header";

export default function Community() {
    return (
        <div className="Community pb-20">
            <Header/>
            <div className="max-w-screen-lg mx-auto w-full mt-16 sm:mt-28 px-5">
                <div className="bg-black rounded-3xl text-text relative max-md:bg-background">
                    <div
                        className="absolute right-0 top-0 w-[38%] h-full bg-cover bg-center bg-no-repeat bg-[url('../assets/img/community.jpg')] rounded-tr-3xl rounded-br-3xl
                        max-md:relative max-md:w-full max-md:h-[200px] max-md:rounded-lg"
                    ></div>
                    <div className="p-16 mr-[38%] max-md:mr-0 max-md:px-0 max-md:py-12">
                        <div className="text-primary uppercase text-xs leading-3 font-medium tracking-widest">
                            WELCOME TO OUR COMMUNITY
                        </div>
                        <div className="text-2xl font-bold mt-3">A place where manual cars are safe</div>
                        <div className="mt-16 leading-[26px] max-md:mt-12 space-y-3">
                            <div className="flex items-center leading-4 relative h-8">
                                <i className="fal fa-car-side text-primary text-sm"></i>
                                <div className="ml-2 font-medium absolute left-5">Share your car build and its story.</div>
                            </div>
                            <div className="flex items-center leading-4 relative">
                                <i className="fal fa-percentage text-primary text-xl ml-[2px]"></i>
                                <div className="ml-2 font-medium absolute left-5">Join brand specific channels with deals.</div>
                            </div>
                            <div className="flex items-center leading-4 relative h-8">
                                <i className="fal fa-gift text-primary text-base"></i>
                                <div className="ml-2 font-medium absolute left-5">Get discount codes and member gifts.</div>
                            </div>
                            <div className="flex items-center leading-4 relative h-8">
                                <i className="fal fa-comment text-primary text-lg"></i>
                                <div className="ml-2 font-medium absolute left-5">Become part of co-building our product.</div>
                            </div>
                        </div>
                        <a href={"https://discord.gg/8f2vqqk7VC"} target={"_blank"} className="button p-4 bg-primary text-black rounded-xl font-bold leading-4 inline-flex items-center mt-16">
                            <div className={"h-[16px] w-[20px] bg-bottom bg-no-repeat bg-[url('../assets/img/icons/discord.svg')] mr-3"}></div>
                            Join our Discord Community
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

