import {useEffect, useState} from 'react';
import {ServerMessage} from "../models/server-message.model";


// Shape of what you want to send
interface SendMessageArg {
    command: 'start-search';
    params: {
        search_string: string;
        perfect_fit?: boolean;
    };
}

// Define the return type of the hook:
// 1) last message from the server
// 2) a function to send a message
// 3) a boolean indicating if the connection is open
type UseWebsocketReturn = [
    ServerMessage | null,
    (message: SendMessageArg) => void,
    boolean
];

// Simple usage:

export default function useWebsocket(): UseWebsocketReturn {
    const [ws, setWs] = useState<WebSocket | null>(null);
    const [message, setMessage] = useState<ServerMessage | null>(null);
    const [ready, setReady] = useState<boolean>(false);

    useEffect(() => {
        // 1. Create the WebSocket connection
        const socket = new WebSocket('wss://aqivlvc8c7.execute-api.eu-west-3.amazonaws.com/dev');
        // const socket = new WebSocket('ws://localhost:3001');

        // 2. Set up event handlers
        socket.onopen = () => {
            console.log('WebSocket connected');
            setReady(true);
        };

        socket.onmessage = (event) => {
            try {
                // The server sends JSON { action: 'starting-search', ... }
                const data = JSON.parse(event.data);
                setMessage(data);
            } catch (err) {
                console.error('Failed to parse WebSocket message:', err);
            }
        };

        socket.onclose = () => {
            console.log('WebSocket disconnected');
            setReady(false);
        };

        // 3. Save the socket in state so we can use it to send messages
        setWs(socket);

        // 4. Cleanup when the component unmounts (close the socket)
        return () => {
            socket.close();
        };
    }, []);

    // 5. Function to send data. Must match the structure the Lambda expects:
    //    { message: { command: 'start-search', params: {...} } }
    const sendMessage = (msg: SendMessageArg) => {
        if (!ws || ws.readyState !== WebSocket.OPEN) {
            console.warn('WebSocket is not open; cannot send message');
            return;
        }

        // The Lambda is expecting a key in the request payload
        const payload = {
            action: 'message',
            message: {
                command: msg.command,
                params: msg.params,
            },
        };
        ws.send(JSON.stringify(payload));
    };

    // 6. Return the last server message, the sender function, and the ready state
    return [message, sendMessage, ready];
}
